.order {
    padding: 3.75rem 0 6.25rem;
    background: #f7f7f7;
    
    &__price {
        margin-top: 1.25rem;
        small {
            display: block;
            font-size: clamp(1.0625rem, 0.9085rem + 0.493vw, 1.6rem);
            font-size: 24px;
            font-weight: 300;
            color: #ffffff;
            margin-bottom: 10px;
            text-align: center;
        }

        .value {
            color: $color-primary;
            font-size: 12px;
            font-weight: bold;
            margin-bottom: 0;
        }

        span {
            display: block;
            font-size: 20px;
            font-size: clamp(1.25rem, 1.0299rem + 0.7042vw, 1.875rem);
            font-weight: 600;
            color: $color-primary;
            border-radius: 0.85rem;
            background: #ffffff;
            padding: 0.35rem 1.25rem;
        }
    }

    .price {
        padding: 0.5rem 0.8rem;
        background-color: $color-secondary;
        color: #fff;
        border-radius: 8px;
        font-size: 0.9rem;
        span {
            font-size: 1.125rem;
            font-weight: 600;
        }
    }

    &-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.25rem;
        font-size: 18px;
    }

    .wrapper {
        display: grid;
        // grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-columns: 100%;
        gap: 1.5rem;
        margin-bottom: 40px;
    }

    &-box {
        width: 100%;
        background: #ffffff;
        padding: 2rem;
        // border-bottom: 6px solid $color-primary;
        border-radius: 0.85rem;
        box-shadow: 0 8px 10px #ccc;

        .content {
            display: flex;
            flex-direction: column;
            align-items: start;
        }

        .icon {
            font-size: 1.85rem;
        }

        .header {
            display: flex;
            flex-flow: row;
            align-items: center;
            border-bottom: 2px solid #e1e1e1;
            padding-bottom: 1rem;
            margin-bottom: 1.5rem;

            .fas {
                margin-right: 16px;
                color: $text-color-secondary;
            }

            .icon {
                width: 44px;
                height: 44px;
                border-radius: 50%;
                border: 2px solid $text-color-secondary;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 32px;
                    height: 32px;
                    display: block;
                    object-fit: contain;
                }
            }
        }

        &__title {
            flex-grow: 1;
            font-size: 20px;
            font-weight: 600;
        }

        .driver {
            display: flex;
            flex-flow: row;
            align-items: center;

            .avatar {
                min-width: 60px;
                min-height: 60px;
                position: relative;
                margin-right: 16px;

                img {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            &__name {
                font-size: clamp(1.03125rem, 0.931rem + 0.1616vw, 1.125rem);
                font-weight: 400;
            }
        }

        .grid {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
            margin-top: 20px;

            &-item {
                flex: 0 0 49%;
                width: 49%;
                margin-bottom: 16px;

                &__label {
                    font-size: 14px;
                    font-weight: 300;
                    color: $text-color-secondary;
                }

                &__value {
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
    }
}
