@import "variables";

.container {
  max-width: 100%;
  padding: 0 3%;
  // width: 1720px;
  margin: 0 auto;
  display: block;
  width: 100%;

  &.container-small {
    width: 1280px;
  }
}

.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &-control {
    margin-bottom: 16px;
    background: #f7f7f7;
    border-radius: 6px;
    padding: 16px;
    display: flex;
    flex-flow: row;
    align-items: center;
    min-height: 60px;
    flex: 0 0 100%;
    width: 100%;
    position: relative;

    &.half {
      flex: 0 0 49%;
      width: 49%;
    }

    .fas,
    .fab {
      color: $text-color-secondary;
      margin-right: 12px;

      &.form-control__warn {
        color: $color-danger;
        margin-right: 0px;
        margin-left: 12px;
      }
    }

    input,
    textarea {
      flex-grow: 1;
      font-size: 16px;
      padding: 0;
      margin: 0;

      &:disabled {
        pointer-events: none;
        opacity: 0.6;
      }
    }

    textarea {
      resize: none;
      min-height: 80px;
      width: 100%;
    }

    &__close {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: #e1e1e1;
      display: flex;
      align-items: center;
      justify-content: center;

      .fas {
        color: $color-danger;
        margin: 0;
      }

      &:hover {
        background: $color-danger;

        .fas {
          color: #ffffff;
        }
      }
    }

    &.no-margin {
      .mat-form-field-wrapper {
        padding-bottom: 0;
      }
      .mat-form-field-underline {
        bottom: 0;
      }
      .mat-form-field-infix {
        border-top-width: 0px;
      }
    }

    &.ng-touched.ng-invalid {
      border-width: 2px;
      border-color: var(--danger);
      border-style: solid;
    }

    &__number {
      max-width: 200px;

      input {
        text-align: center;
      }

      .number-button {
        margin: 0;
        padding: 0;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        border: 1px solid var(--textSecondary);
        margin: 4px;
        cursor: default;

        &:not(:disabled):hover {
          cursor: pointer;
          background-color: var(--textSecondary);
          i {
            color: var(--white);
          }
        }

        &:disabled {
          opacity: 0.4;
        }

        i {
          margin: 0;
        }
      }
    }
  }

  &-image {
    flex: 0 0 100%;
    width: 100%;

    &__pic {
      display: block;
      position: relative;
      width: 100%;
      min-height: 140px;
      background: #f7f7f7;
      border-radius: 12px;
      transition: all 250ms ease-in-out;

      .inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        .fas {
          font-size: 32px;
          color: $text-color-secondary;
        }

        span {
          display: block;
          text-align: center;
          margin-top: 12px;
          font-size: 14px;
          color: $text-color-secondary;
          font-weight: 300;
        }
      }
    }

    .btn {
      margin-top: 16px;
    }

    &:hover {
      .form-image__pic {
        background: #e1e1e1;
      }
    }
  }
}

.tooltip {
  text-align: center;
  position: relative;
  z-index: 10;

  &__icon.fas {
    font-size: 18px;
    color: $color-danger !important;
  }

  &__label {
    background: rgba($color: #000000, $alpha: 0.8);
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 10px;
    color: #ffffff;
    position: absolute;
    top: 100%;
    right: 0;
    white-space: nowrap;
    transition: all 250ms ease-in-out;
    pointer-events: none;
    opacity: 0;
  }

  &:hover {
    .tooltip {
      &__label {
        pointer-events: auto;
        opacity: 1;
      }
    }
  }
}

.btn {
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  min-width: 180px;
  min-height: 2.75rem;
  border-radius: 10px;
  padding: 0 1.8rem;
  span {
    font-size: 1rem;
    // text-transform: uppercase;
    font-weight: 600;
    transition: all 250ms ease-in-out;
  }

  &-default {
    background: $color-primary;

    span {
      color: #ffffff;
    }

    &:hover {
      background: $color-secondary;
    }
  }

  &.btn-block {
    flex: 0 0 100%;
    width: 100%;
  }

  .fas {
    color: #ffffff;
    margin-right: 12px;
  }

  &.disabled {
    background: $text-color-secondary !important;
    pointer-events: none;

    &:hover {
      background: $text-color-secondary !important;
    }
  }

  &.btn-fab {
    min-width: 64px;
    min-height: 64px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    box-shadow: 0 10px 30px rgba($color: #000000, $alpha: 0.38);

    .fas,
    .fab {
      margin-right: 0px;
      font-size: 1.4em;
    }
  }

  &-outline {
    background: transparent;
    border: 1px solid $text-color-secondary;

    span {
      color: $text-color-secondary;
    }

    &:hover {
      border-color: $color-primary;
      background: $color-primary;

      span {
        color: #ffffff;
      }
    }

    &--danger {
      span {
        color: $color-danger;
      }

      &:hover {
        border-color: $color-danger;
        background: $color-danger;
      }
    }
  }

  &.btn-small {
    padding: 8px 20px;
    font-size: 14px;
    min-width: 120px;
    height: 44px;
  }

  &:hover {
    text-decoration: none;
  }
}

.radio {
  &-list {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-item {
    margin-bottom: 16px;
    margin-right: 16px;
    display: flex;
    flex-flow: row;
    align-items: center;
    position: relative;

    input {
      display: none;

      &:checked + label {
        &::before {
          background: $text-color-secondary;
        }
      }
    }

    label {
      padding-left: 28px;
      font-size: 14px;
      font-weight: 300;
      cursor: pointer;

      &::before {
        content: "";
        border: 2px solid $text-color-secondary;
        width: 16px;
        height: 16px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }
  }
}

.header-intern {
  position: relative;

  .wrapper {
    background-size: cover;
    background: url(./../assets/bg-intern.jpg) bottom center no-repeat;
    max-height: 400px;
    height: 25vh;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    margin-top: 1.25rem;
  }

  &__title {
    color: #ffffff;
    font-size: clamp(2.5rem, 2.2328rem + 0.431vw, 2.75rem);
    font-size: clamp(1.375rem, 0.9789rem + 1.2676vw, 2.5rem);
    text-align: center;
    font-weight: 800;
  }

  .back-button {
    position: absolute;
    top: 2rem;
    right: 24px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    z-index: 999;
    background: #ffffff;
    box-shadow: 0 10px 30px rgba($color: #000000, $alpha: 0.18);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 777;

    &:hover {
      box-shadow: 0 10px 30px rgba($color: #000000, $alpha: 0.38);
    }
  }
}

.divisor {
  margin: 8px 0;

  &__point {
    height: 8px;
    width: 8px;
    background: $text-color-secondary;
    display: block;
    border-radius: 4px;
    margin: 4px;
  }
}

.institutional {
  .back-button {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    position: absolute;
    top: 24px;
    left: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    box-shadow: 0 10px 30px rgba($color: #000000, $alpha: 0.18);

    .fa-solid {
      color: var(--textPrimary);
      font-size: 18px;
    }

    &:hover {
      box-shadow: 0 10px 10px rgba($color: #000000, $alpha: 0.1);
    }
  }
  .indication {
    padding: 24px;
    background: #f7f7f7;
    margin-bottom: 20px;

    &-item {
      display: block;
      border-bottom: 1px dashed #aaa;

      & + .indication-item {
        margin-top: 16px;
      }

      a {
        text-decoration: underline;
        color: #000;
        text-transform: uppercase;
        position: relative;
        z-index: 2;
        background: #f7f7f7;
        bottom: -4px;
      }
    }
  }

  &-header {
    min-height: 320px;
    background: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;

    &__title {
      font-size: 50px;
      color: #ffffff;
    }

    &__tag {
      background: #ffffff;
      border-radius: 25px;
      height: 50px;
      min-width: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      color: $text-color-secondary;
      font-size: 18px;
      margin-top: 24px;
    }
  }

  &-content {
    padding: max(2rem, 5.2vw) 0;
    min-height: 100%;

    .text {
      max-width: 1024px;
      margin: 0 auto;
      background: #ffffff;
      padding: 24px;
      font-size: 15px;

      &-section {
        margin-bottom: 30px;
        display: block;

        h3 {
          font-size: 24px;
          color: $text-color-primary;
          margin-bottom: 24px;
          font-weight: 700;
          text-transform: uppercase;
        }

        p {
          font-size: 15px;
          line-height: 2;
          font-weight: 400;
          color: $text-color-primary;

          & + p {
            margin-top: 20px;
          }
        }

        a {
          color: #000;
          text-decoration: underline;
        }

        ul {
          margin: 20px 0;
          padding-left: 40px;

          li {
            font-size: 14px;
            & + li {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}

.html-text {
  width: 90%;
  max-width: 900px;
  margin: 0 auto;
  h3 {
    color: var(--secondary);
    font-weight: 700;
    margin-bottom: 16px;
    font-size: 24px;
  }
  p,
  ul,
  ol {
    margin-bottom: 1rem;
  }
  p {
    line-height: 1.6;
    font-weight: 300;
  }
  ol {
    list-style: decimal;
    list-style-position: inside;
  }
  ul {
    list-style: disc;
  }
  ol,
  ul {
    padding-left: 40px;

    li {
      font-weight: 300;
      line-height: 1.6;

      &::marker {
        color: var(--primary);
        font-weight: 700;
      }

      & + li {
        margin-top: 10px;
      }
    }
  }

  h4 {
    margin-bottom: 0.75rem;
    font-weight: 700;
    color: var(--textSecondary);
  }
}

.title-h1 {
  font-size: 18px;
  font-weight: 700;
  color: $text-color-primary;
}

@media screen and (min-width: 992px) {
  .container-small {
    max-width: 700px;
  }
}

@media screen and (min-width: 1536px) {
  .container-small {
    max-width: 800px;
  }
}

@media screen and (max-width: 768px) {
  .institutional {
    &-header {
      min-height: 200px;
      &__title {
        font-size: 32px;
      }
    }
  }
}

.mat-menu-panel.dialog-panel {
  max-width: inherit;
  width: 100%;
  border-radius: 12px;

  .mat-menu-content {
    padding: 0;
  }
}

.mat-raised-button {
  &.btn-excel {
    background-color: #216f42;
    color: #ffffff;
  }
}

