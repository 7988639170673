@import "variables";

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
p,
blockquote,
hr,
figure,
th,
td {
    margin: 0;
    padding: 0;
}
h3,
h4,
h5,
h6 {
    font-weight: 600;
}
h1,
h2 {
    font-weight: 600;
}
small {
    font-weight: 400;
    font-size: 12px;
}
html,
body {
    -webkit-font-smoothing: antialiased;
    font-size: 100%;
    font-family: "Poppins", sans-serif;
}
strong,
b {
    font-weight: 600;
}
p {
    color: $text-color-primary;
    font-size: 16px;
    line-height: 1.6;
    font-weight: 300;
}
blockquote {
    font-size: 14px;
    font-style: italic;
    padding-left: 40px;
    border-left: 1px solid rgba(18, 18, 18, 0.2);
}
* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
ul,
ol {
    list-style: none;
}
a {
    text-decoration: none;
    color: currentColor;
}
a:hover {
    text-decoration: underline;
}
a,
button {
    transition: all 250ms ease-in-out;
    font: inherit;
}
a:hover,
button:hover {
    cursor: pointer;
}
input,
textarea,
button {
    outline: none;
    border: 0;
    background: transparent;
}
body {
    color: $text-color-primary;
    background-color: #ffffff;
}
.clear {
    width: 100%;
    clear: both;
    display: block;
    height: 0px;
}
img {
    max-width: 100%;
    height: auto;
    word-break: break-all;
}
input,
textarea,
select {
    color: inherit;
    font-family: inherit;

    &::placeholder {
        color: currentColor;
        opacity: 0.85;
    }
}
table {
    width: 100%;
}
::selection {
    background: #ccc;
    color: #fff;
}

.icon {
    position: relative;

    svg {
        display: block;
    }
}

svg {
    fill: currentColor;
}

.text {
    &-right {
        text-align: right;
    }
    &-left {
        text-align: left;
    }
    &-center {
        text-align: center;
    }
}

.hidden {
    display: none !important;
}

.ngxImageZoomContainer {
    width: inherit !important;
    height: inherit !important;
    position: relative;
    overflow: hidden;

    &:hover {
        cursor: zoom-in;
    }

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: top center;
        background-color: #ffffff;
    }
}

@media screen and (min-width: 1025px) {
    .hidden-desktop {
        display: none !important;
    }
}

@media screen and (max-width: 1024px) {
    body {
        padding: 0;
    }

    .hidden-mobile {
        display: none !important;
    }
}
