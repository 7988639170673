$color-primary: #f1a417;
$color-secondary: #2f7096;
$color-danger: #eb2336;

$text-color-primary: #574d4f;
$text-color-secondary: #999999;
$danger: #f44235;
$success: #4aaf4f;
$info: #01a8f4;
$warning: #ffd700;

:root {
  --primary: #f1a417;
  --secondary: #2f7096;
  --textPrimary: #574d4f;
  --textSecondary: #707070;
  --danger: #f44235;
  --success: #4aaf4f;
  --info: #01a8f4;
  --warning: #ffd700;
  --white: #fff;
  --offWhite: #f7f7f7;
  --black: #000;
  --dataPickerBorderColor: #f1a417;
}