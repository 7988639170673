.sign-in {
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../assets/sign-in-background.jpeg");
  background-color: var(--secondary);
  padding: 25px 0;

  .logo {
    min-width: 60px;
    position: relative;
    margin-bottom: max(3.125vw, 2.4rem);

    img {
      width: 60px;
      display: block;
      margin: 0 auto;
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to left top, var(--secondary), var(--primary));
    opacity: 0.9;
    z-index: 2;
    pointer-events: none;
  }

  .box {
    max-width: 420px;
    width: 90%;
    position: relative;
    z-index: 2;
    padding: 40px;
    background: var(--white);
    border-radius: 24px;
    box-shadow:
      rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    background-color: var(--white);

    h2 {
      text-align: center;
      margin-bottom: 0.75rem;
    }

    p {
      text-align: center;
      font-size: 14px;
      color: var(--textSecondary);
      margin-bottom: 1.25rem;
    }

    .actions {
      margin-top: 60px;
      p {
        text-align: center;
        color: rgba(255, 255, 255, 0.5);

        a {
          font-weight: 500;
          color: var(--primary);
        }

        & + p {
          margin-top: 10px;
        }
      }
    }

    a {
      text-align: center;
      display: block;
      text-align: center;
      margin: 16px auto 0;
    }
  }

  .form {
    .mat-hint {
      a {
        color: var(--primary);
      }
    }
    button-container {
      flex: 0 0 100%;
      width: 100%;
    }
  }

  &__link {
    color: var(--white);
    position: relative;
    z-index: 2;
    font-weight: 500;
  }
}
