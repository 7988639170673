@import "variables";

.page {
    &-filter {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        &-item {
            margin-right: 16px;
        }
    }

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 3.75rem;
        margin-bottom: 1.25rem;

        &__buttons {
            display: flex;
            flex-flow: row;
            align-items: flex-end;
            gap: 16px;

            .mat-form-field {
                &.no-margin {
                    .mat-form-field-underline {
                        bottom: 0;
                    }
                    .mat-form-field-wrapper {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }

    &-title {
        font-size: 32px;
        font-weight: 700;
    }

    &-wrapper {
        position: relative;
    }

    &-boxes {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;
        padding: 24px 16px;

        &-buttons {
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 32px;
            flex: 0 0 100%;

            h2 {
                font-size: 18px;
            }

            button {
                margin-left: 16px;
            }
        }
    }

    &-box {
        flex: 0 1 100%;
        width: 100%;
        margin-bottom: 40px;
        box-shadow:
            0 1px 3px rgba(0, 0, 0, 0.12),
            0 1px 2px rgba(0, 0, 0, 0.24);
        border-bottom: 2px solid var(--primary);

        &.half {
            flex: 0 1 49%;
            width: 49%;
        }

        &.third {
            flex: 0 1 32.33%;
            width: 32.33%;
        }

        &-header {
            padding: 16px 24px;
            background: #f7f7f7;

            h3 {
                font-size: 18px;
                color: $color-secondary;
            }
        }

        &-item {
            &__label {
                color: $text-color-secondary;
                font-size: 14px;
                display: block;
                margin-bottom: 5px;
            }

            &__value {
                font-size: 16px;
            }
        }

        &-content {
            padding: 16px 24px;

            ul {
                li + li {
                    margin-top: 10px;
                }
            }
        }
    }

    &-content {
        box-shadow:
            0 1px 3px rgba(0, 0, 0, 0.12),
            0 1px 2px rgba(0, 0, 0, 0.24);
        position: relative;
        padding: 24px 40px;
    }

    &-search {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
    }

    &-info {
        border-radius: 8px;
        background-color: var(--white);
        padding: 0.85rem 24px;
        margin-bottom: 1.25rem;
        box-shadow:
            0 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 2px -1px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: row;
        // border-top: 3px solid var(--primary);
        flex-wrap: wrap;

        &__item {
            max-width: 25%;

            & + .page-info__item {
                margin-left: 1rem;
                padding-left: 1rem;
                border-left: 1px solid rgba(0, 0, 0, 0.1);
            }

            span {
                font-size: 12px;
            }

            h2 {
                font-size: 18px;
                font-weight: 700;
            }
        }
    }

    &-table {
        position: relative;

        &__search {
            font-size: 14px;
            width: 100%;
        }

        &-container {
            box-shadow:
                0 1px 3px rgba(0, 0, 0, 0.12),
                0 1px 2px rgba(0, 0, 0, 0.24);
            position: relative;
        }

        /* Column Widths */
        .mat-column-number,
        .mat-column-state {
            max-width: 64px;
        }

        .mat-column-created {
            max-width: 124px;
        }

        td:last-child,
        th:last-child {
            text-align: right;
        }

        td.mat-cell:not(:first-child):not(:last-child),
        th.mat-footer-cell:not(:first-child):not(:last-child),
        th.mat-header-cell:not(:first-child):not(:last-child) {
            padding: 0 0px 0 8px;
        }

        td.mat-cell {
            max-width: 300px;
        }

        &-image {
            height: 75px;
            width: 75px;
            object-fit: cover;
            display: block;
        }
    }

    &-tab-group {
        flex: 0 0 100%;
        width: 100%;
    }

    &-form {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        background: var(--white);
        box-shadow:
            rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
            rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
            rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
        padding: 23px 40px;
        border-bottom: 4px solid var(--primary);
        position: relative;

        &__title {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 23px;
            color: var(--primary);
            flex: 0 1 100%;
            width: 100%;
        }

        hr {
            flex: 0 1 100%;
            width: 100%;
            border: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            margin: 30px 0;
        }

        &-loading {
            position: absolute !important;
            top: 0;
            left: 0;
            z-index: 100;

            &-shade {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: rgba(0, 0, 0, 0.5);
                z-index: 99;
            }
        }

        &-control {
            flex: 0 1 100%;
            width: 100%;
            margin-bottom: 1.25em;

            &.half {
                flex: 0 1 49%;
                width: 49%;
            }

            &.third {
                flex: 0 1 32.33%;
                width: 32.33%;
            }

            &.two-third {
                flex: 0 1 65.66%;
                width: 65.66%;
            }

            &.quad {
                flex: 0 1 24%;
                width: 24%;
            }

            & > label {
                margin-bottom: 10px;
                display: block;
                color: rgba(0, 0, 0, 0.7);
                font-size: 13px;
            }

            &.has-icon {
                position: relative;
                padding-left: 50px;
            }

            &__icon {
                width: 50px;
                height: 50px;
                position: absolute;
                left: -50px;
                top: 50%;
                transform: translateY(-50%);

                svg {
                    width: 22px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .checkbox-wrapper {
                display: flex;
                flex-direction: row;
                gap: 1rem;
            }
        }

        .page-radio-group {
            display: flex;
            flex-direction: column;
            margin: 15px 0;

            &-label {
                font-size: 14px;
                color: var(--primary);
            }
        }

        .page-radio-button {
            margin: 5px;
        }

        &-image {
            position: relative;
            img {
                width: 240px;
                display: block;
                margin-bottom: 10px;
            }

            &__button {
                position: absolute;
                left: 16px;
                top: 16px;
                background: var(--danger);
                height: 32px;
                width: 32px;
                padding: 0;
                margin: 0;
                border-radius: 50%;
                color: #fff;
            }
        }
    }

    &-filters {
        display: flex;
        flex-flow: row;
        align-items: center;
        gap: 16px;
    }
}

.loading-shade {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 56px;
    right: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.15);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rate-limit-reached {
    color: $color-primary;
    max-width: 360px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
}
