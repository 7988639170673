@use "@angular/material" as mat;
@import "variables";
@import "reset";
@import "global";
@import "auth";
@import "admin";
@import "service";
@import "~ngx-toastr/toastr";

@include mat.core();

$mat-myapp-primary: (
    50: #fdf4e3,
    100: #fbe4b9,
    200: #f8d28b,
    300: #f5bf5d,
    400: #f3b23a,
    500: #f1a417,
    600: #ef9c14,
    700: #ed9211,
    800: #eb890d,
    900: #e77807,
    A100: #ffffff,
    A200: #ffecdc,
    A400: #ffd0a9,
    A700: #ffc390,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000
    )
);

$mat-myapp-accent: (
    50: #e6eef2,
    100: #c1d4e0,
    200: #97b8cb,
    300: #6d9bb6,
    400: #4e85a6,
    500: #2f7096,
    600: #2a688e,
    700: #235d83,
    800: #1d5379,
    900: #124168,
    A100: #9ed0ff,
    A200: #6bb7ff,
    A400: #389eff,
    A700: #1f92ff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #ffffff
    )
);

$mat-myapp-warn: (
    50: #ffffff,
    100: #fee9e8,
    200: #fbb8b3,
    300: #f7786f,
    400: #f65d52,
    500: #f44235,
    600: #f22718,
    700: #e01b0c,
    800: #c3170b,
    900: #a61409,
    A100: #ffffff,
    A200: #ffc7c3,
    A400: #ff685d,
    A700: #fa5548,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000
    )
);

$primary: mat.define-palette($mat-myapp-primary);
$accent: mat.define-palette($mat-myapp-accent);
$warn: mat.define-palette($mat-myapp-warn);
$theme: mat.define-light-theme($primary, $accent, $warn);
@include mat.all-component-themes($theme);

.page-form {
    .custom-dropzone {
        height: 250px;
        background: #424242;
        color: #fff;
        border: 2px dashed rgba(255, 255, 255, 0.7);
        border-radius: 0px;
        font-size: 20px;
        width: 100%;
        margin-bottom: 16px;
        transition: all 200ms;
    }

    .custom-dropzone.ngx-dz-hovered {
        border: 2px solid $color-primary;
    }
}

.toast-container {
    .ngx-toastr {
        box-shadow: none;
    }
}
